import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/tipo-servico';

export default  {

  dificuldades : Object.freeze([
    {id: 'MUITO_LEVE', nome: '1 - Muito Leve'},
    {id: 'LEVE', nome: '2 - Leve'},
    {id: 'MODERADO', nome: '3 - Moderado'},
    {id: 'EXIGENTE', nome: '4 - Exigente'},
    {id: 'MUITO_EXIGENTE', nome: '5 - Muito exigente'},
    {id: 'DESAFIADOR', nome: '6 - Desafiador'},
    {id: 'MUITO_DESAFIADOR', nome: '7 - Muito desafiador'},
  ]),

  tipoAtoSinter: Object.freeze([
    'Nenhum',
    'Alteração de Registro de Extrato - CNG',
    'Alteração de Registro exclusivamente para Conservação',
    'Alteração de Registro exclusivamente para Conservação Fiscal',
    'Alteração de Registro Integral - CNG',
    'Alteração de Registro Integral Comum',
    'Cancelamento de Registro de Extrato - CNG',
    'Cancelamento de Registro Integral - CNG',
    'Cancelamento de Registro Integral Comum',
    'Registro Primitivo Exclusivamente para Conservação',
    'Registro Primitivo Exclusivamente para Conservação - Fiscal',
    'Registro Primitivo Integral - CNG',
    'Registro Primitivo Integral - Comum'
  ]),

  subtipoAtoSinter : Object.freeze([
    'Aditamento/alteração',
    'Anulação/cancelamento de averbação',
    'Arrolamento fiscal',
    'Averbação premonitória/citação/protesto contra alienação',
    'Averbação sem alterações',
    'Cláusulas restritivas/bem de família-AV',
    'Garantia real ou pessoal',
    'Indisponibilidade/arresto/penhora/sequestro',
    'Ordem judicial - outras',
    'Retificação',
    'Transmissão da propriedade ou de direitos',
    'Transmissão em garantia da propriedade ou de direitos',
  ]),

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids){
    return Http.post(`${path}/simple`, ids);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getByDominio(id, resetCache = ''){
    return Http.get(`${path}/dominio/${id}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getByDominioSimples(dominio){
    return Http.get(`${path}/dominio-simples/${dominio}`).then(l => l.map(i => {
      i.opcoes = JSON.parse(i.opcoes);
      return i;
    }));
  },

  async getByDominioSimplesIds(dominio, ids){
    return Http.post(`${path}/dominio-simples/${dominio}`, ids).then(l => l.map(i => {
      i.opcoes = JSON.parse(i.opcoes);
      return i;
    }));
  },

  async getByDominioLivroSimples(id, livro){
    return Http.get(`${path}/dominio-livro-simples/${id}/${livro}`).then(l => l.map(i => {
      i.opcoes = JSON.parse(i.opcoes);
      return i;
    }));
  },

  async getByDominioSimplesTodos(id){
    return Http.get(`${path}/dominio-simples-todos/${id}`).then(l => l.map(i => {
      i.opcoes = JSON.parse(i.opcoes);
      return i;
    }));
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async getTiposCustaApuracao() {
    return Http.get(`${path}/tribunal/tipo-custa-apuracao`);
  },

  async getTiposCobrancaTribunal(dominio = ''){
    return Http.get(`${path}/tribunal/tipo-cobranca/${dominio}`);
  },

  async getChecklistById(id){
    return Http.get(`${path}/checklist/${id}`);
  },

  async importar(id, base){
    return Http.get(`${path}/importar/${id}/${base}`);
  },

  async getTiposAtoTribunal(dominio = ''){
    return Http.get(`${path}/tribunal/tipo-ato/${dominio}`);
  },

  async getTiposAtoTribunalTratado(item, dominio) {
    let tiposTribunal = await this.getTiposAtoTribunal(dominio);

    if (!item?.codigoTj) {
      item.codigoTj = (((tiposTribunal || []).find(e => e.id === item?.tipoServico?.tipoTribunal))?.codigo) || '';
      item.codigoTj = item.codigoTj + '';
    }

    tiposTribunal = tiposTribunal.map(t => {
      return {id: t.codigo + '', nome: t.nome}
    });

    return {
      tiposTribunal,
      item
    }
  }
}
