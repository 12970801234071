<template>

  <card :titulo="titulo">

    <small-loading :loading="loading" />

    <template v-if="!loading">

      <div class="small text-muted mb-3" v-if="item.selado || item.selada">
        <span class="mb-2"><b>Data da Selagem do Item:</b> {{$formatters.datetime(item.selado? item.selado : item.selada)}}</span>
        <a v-if="permitirEdicaoDataSelagem" class="icon mr-2 col-2" @click.prevent="editar()" href>
          <i class="fal fa-edit" title="Editar"></i>
        </a>
      </div>

      <div class="small text-muted mb-3" v-if="item.codigoFiscal">
        <span class="mb-2"><b>Código Fiscal:</b> {{item.codigoFiscal}}</span>
      </div>

      <alert-banner v-if="custaManualDescricao" :message="custaManualDescricao" type="alert-outline-warning" :class="`mb-4`"/>

      <alert-banner v-if="isIsento" :message="tipoRegistroIsento" type="alert-outline-warning" :class="`mb-4`"/>

      <template>

        <p v-if="!exibir" class="alert alert-danger alert-sm mb-2" v-html="erro"></p>

        <template v-if="lista.length">
          <card-table>
            <thead>
            <tr>
              <th class="text-left" width="50px">QTD</th>
              <th>ITEM</th>
              <th class="text-right" width="120px">EMOLUMENTO</th>
              <th class="text-right" width="120px">ADICIONAIS</th>
              <th class="text-right" width="120px">TOTAL</th>
            </tr>
            </thead>

            <tbody>

            <template v-for="(linha, index) in lista">

              <tr v-if="linha.descricao || linha.emolumentos" class="pointer cinza" @click="fundos(linha)" :key="'custas'+index">
                <td class="text-center">{{linha.quantidade || 1}}</td>
                <td>
                  {{index + 1}}) {{linha.descricao}} {{ linha.codigoTj ? '[' +linha.codigoTj+ ']': ''}}
                  <template v-if="linha.baseLegal">
                    <p class="small text-muted">{{linha.baseLegal}}</p>
                  </template>
                </td>
                <td class="text-right">{{ $formatters.currency(linha.emolumentos, 2, '-') }}</td>
                <td class="text-right">{{ $formatters.currency((linha.fundosEstaduais || 0) + (linha.taxaJudiciaria || 0) + (linha.iss || 0) + (linha.custoSelo || 0), 2, '-') }}</td>
                <td class="text-right">{{ $formatters.currency(((linha.total) * (linha.quantidade || 1)), 2, '-')}}</td>
              </tr>

              <tr v-else :key="'custas_else'+index">
                <td colspan="100%" class="alert alert-warning alert-sm" v-html="retornarErros(linha)"></td>
              </tr>

              <template v-if="linha.detalhar">

                <tr v-for="(valor, taxa) in linha.fundos" class="linha-taxa bg-transparent" :key="taxa+index">
                  <td colspan="2" ></td>
                  <td class="text-right bg-sidebar">{{(taxa||'').replace('_', ' ')}}</td>
                  <td class="text-right bg-sidebar">{{ $formatters.currency(valor, 2, '-')}}</td>
                </tr>

                <tr v-if="linha.iss && linha.iss > 0" class="linha-taxa bg-transparent" :key="'iss'+index">
                  <td colspan="2" ></td>
                  <td class="text-right bg-sidebar">ISS</td>
                  <td class="text-right bg-sidebar">{{ $formatters.currency(linha.iss, 2, '-')}}</td>
                  <td></td>
                </tr>

                <tr v-if="linha.taxaJudiciaria && linha.taxaJudiciaria > 0" class="linha-taxa bg-transparent" :key="'taxa-judiciaria'+index">
                  <td colspan="2" ></td>
                  <td class="text-right bg-sidebar">{{taxaJudiciariaLabel}}</td>
                  <td class="text-right bg-sidebar">{{ $formatters.currency(linha.taxaJudiciaria, 2, '-')}}</td>
                  <td></td>
                </tr>

                <tr v-if="linha.custoSelo && linha.custoSelo > 0" class="linha-taxa bg-transparent" :key="'custoselo'+index">
                  <td colspan="2"></td>
                  <td class="text-right bg-sidebar">Selo</td>
                  <td class="text-right bg-sidebar">{{ $formatters.currency(linha.custoSelo, 2, '-')}}</td>
                  <td></td>
                </tr>

<!--                <tr v-if="linha.vrc && linha.vrc > 0" class="linha-taxa bg-transparent" :key="'custoselo'+index">-->
<!--                  <td colspan="2"></td>-->
<!--                  <td class="text-right bg-sidebar">VRC</td>-->
<!--                  <td class="text-right bg-sidebar">{{ $formatters.currency(linha.vrc, 2, '-')}}</td>-->
<!--                  <td></td>-->
<!--                </tr>-->

              </template>

              <template v-for="(linhaSelo, indexSelo) in selosUtilizados(linha)">

                  <tr class="pointer cinza" @click="fundos(linhaSelo)" :key="linhaSelo.codigo+'custas'+indexSelo">
                    <td class="no-background"></td>
                    <td>
                      <div class="small">
                        {{indexSelo + 1}}) {{ linhaSelo.codigo }}<template v-if="linha.codigoValidador">-{{linha.codigoValidador}}</template> -
                        <span :class="classeSelo(linhaSelo)">{{linhaSelo.utilizacao | datahora}}</span>
                        <v-button :run="openConsultaSelo" :params="[linhaSelo]" :button="false" class="icon ml-1">
                          <i class="fas fa-external-link-alt" title="Consultar Selo"></i>
                        </v-button>
                      </div>
                      <div class="small" v-if="linha.idAtoIsentado && $root.config.estado == 'GO'">ID Isentado: {{linhaSelo.idAtoIsentado}}</div>
                      <div class="small" v-if="linhaSelo.envio">Envio: {{linhaSelo.envio | datahora}} {{linhaSelo.isentado ? ` - ${linhaSelo.isentado}` : ''}}</div>
                      <div class="small" v-if="linhaSelo.cancelamento">Cancelamento: {{linhaSelo.cancelamento | datahora}}</div>
                    </td>
                    <td class="text-right">{{ $formatters.currency(linha.emolumentos, 2, '-') }}</td>
                    <td class="text-right">{{ $formatters.currency((linha.fundosEstaduais || 0) + (linha.taxaJudiciaria || 0) + (linha.iss || 0) + (linha.custoSelo || 0), 2, '-') }}</td>
                    <td class="text-right">{{ $formatters.currency(linha.total, 2, '-')}}</td>
                  </tr>

                  <template v-if="linhaSelo.detalhar && mostrarDetalhesSelo">

                    <tr v-for="(value, name) in linha.fundos" class="linhaSelo-taxa bg-transparent" :key="linhaSelo.codigo+'custas'+name">
                      <td colspan="2"></td>
                      <td class="text-right bg-sidebar">{{ (name||'').replace('_', ' ') }}</td>
                      <td class="text-right bg-sidebar">{{ $formatters.currency(value, 2, '-')}}</td>
                      <td></td>
                    </tr>

                    <tr v-if="linha.iss && linha.iss > 0" class="linhaSelo-taxa bg-transparent" :key="linhaSelo.codigo+'iss'">
                      <td colspan="2"></td>
                      <td class="text-right bg-sidebar">ISS</td>
                      <td class="text-right bg-sidebar">{{ $formatters.currency(linha.iss, 2, '-')}}</td>
                      <td></td>
                    </tr>

                    <tr v-if="linha.taxaJudiciaria && linha.taxaJudiciaria > 0" class="linhaSelo-taxa bg-transparent" :key="linhaSelo.codigo+'taxa-judiciaria'">
                      <td colspan="2"></td>
                      <td class="text-right bg-sidebar">{{taxaJudiciariaLabel}}</td>
                      <td class="text-right bg-sidebar">{{ $formatters.currency(linha.taxaJudiciaria, 2, '-')}}</td>
                      <td></td>
                    </tr>

                    <tr v-if="linha.custoSelo && linha.custoSelo > 0" class="linhaSelo-taxa bg-transparent" :key="linhaSelo.codigo+'custoselo'">
                      <td colspan="2"></td>
                      <td class="text-right bg-sidebar">Selo</td>
                      <td class="text-right bg-sidebar">{{ $formatters.currency(linha.custoSelo, 2, '-')}}</td>
                      <td></td>
                    </tr>

                  </template>


              </template>

              <tr v-if="item.selando && selosUtilizados(linha).length" :key="'selos_else'+index">
                <td class="no-background"></td>
                <td colspan="100%" class="alert alert-warning alert-sm">Contém selos em processamento</td>
              </tr>

            </template>

            </tbody>

          </card-table>
        </template>

        <div v-else class="alert alert-outline-info">
          Não há registros para exibir
        </div>

        <p v-if="aviso && !isDetalhamentoProtocoloOuCertidao" class="alert alert-warning alert-sm mb-0" v-html="aviso"></p>

        <template v-if="selosExtras.length > 0">

          <card-table class="mt-2">
            <thead>
              <tr>
                <th>{{tituloSelosExtras}}</th>
                <th class="text-right" width="120px"></th>
                <th class="text-right" width="120px"></th>
                <th class="text-right" width="120px"></th>
              </tr>
            </thead>

            <tbody>

            <template v-for="(linha, index) in selosExtras">

              <tr class="pointer cinza" @click="fundos(linha)" :key="linha.codigo+'custas'+index">

                <td> {{index + 1}}) {{linha.descricao}} {{$root.isVhlUser && linha.codigoAto ? '('+linha.codigoAto+')': ''}}
                  <div class="small">
                    {{ linha.codigo }}<template v-if="linha.codigoValidador">-{{linha.codigoValidador}}</template> - <span :class="classeSelo(linha)">{{linha.utilizacao | datahora}}</span>

                    <template>
                      <v-button :run="openConsultaSelo" :params="[linha]" :button="false" class="icon ml-1">
                        <i class="fas fa-external-link-alt" title="Consultar Selo"></i>
                      </v-button>
                    </template>

                  </div>
                  <div class="small" v-if="linha.idAtoIsentado && $root.config.estado == 'GO'">ID Isentado: {{linha.idAtoIsentado}}</div>
                  <div class="small" v-if="linha.envio">Envio: {{linha.envio | datahora}} {{linha.isentado ? ` - ${linha.isentado}` : ''}}</div>
                  <div class="small" v-if="linha.cancelamento">Cancelamento: {{linha.cancelamento | datahora}}</div>
                  <div class="small" v-if="linha.retificando">Retificando: {{linha.retificando}}</div>
                </td>

                <td class="text-right">{{ $formatters.currency(linha.emolumentos, 2, '-') }}</td>
                <td class="text-right">{{ $formatters.currency((linha.fundosEstaduais || 0) + (linha.taxaJudiciaria || 0) + (linha.iss || 0) + (linha.custoSelo || 0), 2, '-') }}</td>
                <td class="text-right">{{ $formatters.currency(linha.total, 2, '-')}}</td>

              </tr>

              <template v-if="linha.detalhar && mostrarDetalhesSelo">

                <tr v-for="(value, name) in linha.fundos" class="linha-taxa bg-transparent" :key="linha.codigo+'custas'+name">
                  <td></td>
                  <td class="text-right bg-sidebar">{{ tratarNomeFundo(name) }}</td>
                  <td class="text-right bg-sidebar">{{ $formatters.currency(value, 2, '-')}}</td>
                  <td></td>
                </tr>

                <tr v-if="linha.iss && linha.iss > 0" class="linha-taxa bg-transparent" :key="linha.codigo+'iss'">
                  <td></td>
                  <td class="text-right bg-sidebar">ISS</td>
                  <td class="text-right bg-sidebar">{{ $formatters.currency(linha.iss, 2, '-')}}</td>
                  <td></td>
                </tr>

                <tr v-if="linha.taxaJudiciaria && linha.taxaJudiciaria > 0" class="linha-taxa bg-transparent" :key="linha.codigo+'taxa-judiciaria'">
                  <td></td>
                  <td class="text-right bg-sidebar">{{taxaJudiciariaLabel}}</td>
                  <td class="text-right bg-sidebar">{{ $formatters.currency(linha.taxaJudiciaria, 2, '-')}}</td>
                  <td></td>
                </tr>

                <tr v-if="linha.custoSelo && linha.custoSelo > 0" class="linha-taxa bg-transparent" :key="linha.codigo+'custoselo'">
                  <td></td>
                  <td class="text-right bg-sidebar">Selo</td>
                  <td class="text-right bg-sidebar">{{ $formatters.currency(linha.custoSelo, 2, '-')}}</td>
                  <td></td>
                </tr>

              </template>

            </template>

            </tbody>

          </card-table>

        </template>

      </template>

      <card titulo="Selos pendentes" v-if="selosPendentes && selosPendentes.length">
        <card-table class="table-borderless">
          <thead>
            <tr>
              <th width="160px">Cadastro</th>
              <th width="160px">Verificação</th>
              <th>Log</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="linha in selosPendentes" :key="linha.id" :class="{'menos-destaque' : linha.convertido}">

              <td>{{linha.geracao | datahora}}</td>
              <td>{{linha.tentativa | datahora }}</td>
              <td class="small">[{{linha.codigoAto}}] {{linha.log }}</td>

            </tr>
          </tbody>
        </card-table>
      </card>

    </template>

  </card>

</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import SeloBusiness from "@/business/SeloBusiness.js";
import AlertBanner from "@/components/alert/AlertBanner.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import SmallLoading from "@/components/SmallLoading.vue";
import ModalConsultaSelo from "@/views/Administracao/modulos/selo/GO/ModalConsultaSelo.vue";
import AtribuicaoBusiness from "@/business/crud/AtribuicaoBusiness";
import ModalEdicaoDataSelagem from "@/components/Protocolo/ModalEdicaoDataSelagem";
import EventBus from "@/commons/EventBus";

export default {
    name: "CustasContainer",
    components: {SmallLoading, CardTable, Card, AlertBanner},

    props: {
      tipo: String,
      titulo: String,
      item: Object,
      protocolo: Object,
      mostrarSelos : {type: Boolean, default: () => true}
    },

    data() {
      return {
        loading: true,
        selos: [],
        listaFundos: [],
        selosExtras: [],
        selosPendentes: [],
        temAtribuicao: false,
        //custas : null
      }
    },

    async mounted(){
      await this.load();
      EventBus.$on('load', this.load);
    },

    computed: {

      taxaJudiciariaLabel(){
        return this.$root.isEstadoCE ? "FERMOJU" : "Taxa Judiciária";
      },

      estado(){
        return this.$root.config.estado;
      },

      mostrarDetalhesSelo(){
        return this.estado == 'GO';
      },

      tituloSelosExtras(){
        return this.estado == "SC" ? "Selos Utilizados" : "Outros Selos Vinculados";
      },

      selado() {
        return this.item?.selado || this.item?.selada;
      },

      isIsento() {
        return (this.item.isento || this.item.isIsenta || this.item.tipoRegistro === 'ISENTO') || !!this.item.isento;
      },

      custas() {
        return this.item?.custas || {};
      },

      exibir() {
        return !(this.custas?.erro?.descricao || this.custas?.erro);
      },

      lista () {
        return Object.keys(this.custas || {})
          .filter(e => !['aviso', 'erro'].includes(e))
          .map(k => {
            let o = {codigoAto: k, ...this.custas[k]};
            return o;
          });
      },

      erro() {
        return this.custas?.erro?.descricao || this.custas?.erro || '-';
      },

      aviso() {
        return this.custas?.aviso?.descricao;
      },

      tipoRegistroIsento() {
        switch (this.tipo) {
          case 'prenotacao': return 'Prenotação Isenta';
          case 'certidao': return 'Certidão Isenta';
          case 'busca': return 'Busca Isenta';
          case 'ato': return 'Ato Isento';
          case 'custa-adicional': return 'Custa Adicional Isenta';
          default: return 'Busca Isenta';
        }
      },

      custaManualDescricao() {
        if(!this.item.custasManual){
          return false;
        }
        switch (this.tipo) {
          case 'prenotacao': return 'Prenotação com cálculo de emolumentos e custas bloqueado';
          case 'certidao': return 'Certidão com cálculo de emolumentos e custas bloqueado';
          case 'busca': return 'Busca com cálculo de emolumentos e custas bloqueado';
          case 'ato': return 'Ato com cálculo de emolumentos e custas bloqueado';
          case 'custa-adicional': return 'Custa Adicional com custas de emolumentos e valores bloqueado';
          default: return 'Busca com cálculo de emolumentos e custas bloqueado';
        }
      },

      usuario() {
        return this.$root.usuarioLogado;
      },

      isDetalhamentoProtocoloOuCertidao(){
        return this.tipo === 'protocolo-certidao' || this.tipo === 'protocolo';
      },

      permitirEdicaoDataSelagem(){
        return this.$root.getPermission({id: 'PERMITIR_EDITAR_SELO'}) && ['custa-adicional', 'ato', 'certidao'].includes(this.tipo)
      },

    },

    methods: {

      tratarNomeFundo(nome){
        let n = (nome||'').replace('_', ' ');
        if(n === 'DEFENSORIA'){
          return 'Defensoria';
        }
        return n;
      },

      async editar() {
        let retorno  = await FrontBusiness.openModal(ModalEdicaoDataSelagem,{dto: this.item, tipo: this.tipo, protocolo: this.protocolo});
        this.item.selado = retorno.selado;
        await this.load();
      },

      classeSelo(selo){

        if(this.item.selado && this.$formatters.date(selo.utilizacao) != this.$formatters.date(this.item.selado)){
          return 'text-danger';
        }

        return '';
      },

      fundos(linha) {
        this.$set(linha, 'detalhar', !linha.detalhar);
        this.$forceUpdate();
      },

      retornarErros(linha) {
        return Array.isArray(linha) ? linha.join('<br>') : linha;
      },

      async openConsultaSelo(selo){
        await FrontBusiness.openModal(ModalConsultaSelo, {consultarSeloId: selo?.id, consultarSelo: selo?.codigo, localSelo: true});
      },

      async load() {
        this.loading = true;

         if(this.protocolo.etapa?.id && this.protocolo.tipoServico?.id){
          this.temAtribuicao = await AtribuicaoBusiness.temAtribuicao(this.protocolo.etapa.id, this.protocolo.tipoServico.id, this.usuario.id);
        }

        if(this.item?.id){

          if(this.selado || this.mostrarSelos){
            this.selos = await SeloBusiness.getSelos(this.tipo, this.item?.id, this.estado);
          }

          switch (this.tipo.toString().toLowerCase()) {
            case 'certidao':
              this.selosPendentes = await SeloBusiness.getSelosPendentesCertidao(this.item?.id).then(l => (l || []).filter(s => !s.convertido));
              break;
            case 'ato':
              this.selosPendentes = await SeloBusiness.getSelosPendentesAto(this.item?.id).then(l => (l || []).filter(s => !s.convertido));
              break;
            case 'prenotacao':
              this.selosPendentes = await SeloBusiness.getSelosPendentesPrenotacao(this.item?.id).then(l => (l || []).filter(s => !s.convertido));
              break;
            case 'protocolo':
              this.selosPendentes = await SeloBusiness.getSelosPendentesProtocolo(this.item?.id).then(l => (l || []).filter(s => !s.convertido));
              break;
            case 'busca':
              this.selosPendentes = await SeloBusiness.getSelosPendentesBuscas(this.item?.id).then(l => (l || []).filter(s => !s.convertido));
              break;
            case 'custa-adicional':
              this.selosPendentes = await SeloBusiness.getSelosPendentesCustasAdicionais(this.item?.id).then(l => (l || []).filter(s => !s.convertido));
              break;
          }

        }

        if(this.item?.custa) {
          Object.keys(this.item?.custas).forEach(key => {
            this.item.custas[key].codigoAto = key;
          });
        }

        const listaCodigoAtosSelos = this.lista.map(linha => linha.codigoAto);
        this.selosExtras = this.selos.filter(l => !listaCodigoAtosSelos.includes(l.codigoAto));

        this.loading = false;

      },

      selosUtilizados(linha){
        return this.selos.filter(l => l.codigoAto === linha.codigoAto);
      }

    }

  }
</script>

<style scoped>
  .linha-taxa td, .linhaSelo-taxa td {
    padding: .25rem .75rem;
  }
  .no-background{
    background-color: #1c1e24;
  }
</style>
