export default {

  getMascarasGuiaTribunal($root) {
    if($root.isEstadoPE){
      return ['########'];
    }
    if($root.isEstadoBA){
      return ['####.###.######'];
    }
    if($root.isEstadoSE){
      return ['####.###.######'];
    }
  },

  getTamanhoGuiaTribunal($root) {
    if($root.isEstadoPE){
      return 8;
    }

    if($root.isEstadoBA){
      return 15;
    }

    if($root.isEstadoSE){
      return 15;
    }
  },

  getNotasAcrescimo() {
    return [];
  },

  getNotasDesconto() {
    return [];
  }
}
